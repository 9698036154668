import { CloseIcon } from "components/IconsOnly";
import { ImageModalType } from "components/ImagesRecord";
import "css/Images.css";
import { videoFormats } from "models/mediaFormats";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { setImageModalDescription } from "redux/Order/action";
import { IRootState } from "store";

interface ViewImageModalProps {
  imageModal: { type: ImageModalType; fileName?: string; mediaId?: number };
  image?: string;
  handleExit: () => void;
  onSubmit: () => void;
  submitEdit?: (args0: number) => void;
  isUploadMode?: boolean;
}

export default function ViewImageModal(props: ViewImageModalProps) {
  const { imageModal, image, handleExit, onSubmit, submitEdit, isUploadMode = false } = props;
  const [originalDescription, setOriginalDescription] = useState("");
  const [enlargeImage, setEnlargeImage] = useState("");
  const testRef = useRef<HTMLTextAreaElement>(null);
  const reduxImage = useSelector((state: IRootState) => state.orderById.image);
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const orderCompleteDate = !!orderData.completeDate ? new Date(orderData.completeDate) : undefined;
  const is7DaysAfter =
    !!orderCompleteDate && Date.now().valueOf() > orderCompleteDate.setDate(orderCompleteDate.getDate() + 7).valueOf();

  useEffect(() => {
    setOriginalDescription(reduxImage.imageDescription);
  }, []);

  const MAX_HEIGHT = "45vh";
  const fileNameSplit = imageModal.fileName ? imageModal.fileName.split(".") : [];
  const fileExtension = fileNameSplit[fileNameSplit.length - 1];
  const allowToEdit = (!is7DaysAfter && userRole === "WORKER") || userRole !== "WORKER";

  return (
    <Modal size="xl" isOpen={!!imageModal.type} centered contentClassName="imageModal">
      <Container size="xl" className="p-4">
        {imageModal.type === "ADD" && (
          <Row className="flex-center">
            <h4 className="flex-center">輸入媒體描述</h4>
          </Row>
        )}

        <Row className="flex-center">
          {imageModal.fileName && videoFormats.includes(fileExtension) ? (
            <>
              <video
                style={{ maxHeight: MAX_HEIGHT }}
                controls={true}
                src={
                  imageModal.type === "ADD"
                    ? image
                    : imageModal.type === "VIEW"
                    ? `https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${imageModal.fileName}`
                    : ""
                }
              />
            </>
          ) : (
            <>
              {imageModal.type === "ADD" ? (
                <></>
              ) : (
                <img
                  className="pointer"
                  onClick={() => setEnlargeImage(imageModal.fileName?.toString()!)}
                  style={{ maxHeight: MAX_HEIGHT, width: "auto" }}
                  alt={`${imageModal.fileName}`}
                  src={
                    imageModal.type === "VIEW"
                      ? `https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${imageModal.fileName}`
                      : ""
                  }
                />
              )}
            </>
          )}
        </Row>

        <Row className="p-3">
          <div className="mx-1">描述:</div>
          <TextareaAutosize
            readOnly={!allowToEdit && !isUploadMode}
            rows={4}
            ref={testRef}
            className={`mx-1 ${!isUploadMode && "pointer-events-none"}`}
            value={reduxImage.imageDescription}
            onChange={(e) => {
              if (allowToEdit) {
                dispatch(setImageModalDescription(e.currentTarget.value));
              }
            }}
          />
        </Row>
        <Row className="my-4 d-flex justify-content-around">
          {isUploadMode ? (
            <Col md={6} className="flex-center">
              <Button style={{ width: "50%" }} onClick={handleExit}>
                {"取消"}
              </Button>
            </Col>
          ) : (
            <Col md={12} className="flex-center">
              <Button style={{ width: "50%" }} onClick={handleExit}>
                {"返回"}
              </Button>
            </Col>
          )}
          <Col md={6} className="flex-center">
            {isUploadMode &&
              (imageModal.type === "ADD" ? (
                <Button style={{ width: "50%" }} color="primary" onClick={onSubmit}>
                  確認上傳
                </Button>
              ) : (
                imageModal.type === "VIEW" &&
                imageModal.mediaId && (
                  <Button
                    style={{ width: "50%" }}
                    color="primary"
                    disabled={originalDescription === reduxImage.imageDescription}
                    onClick={submitEdit ? () => submitEdit(imageModal.mediaId!) : () => {}}
                  >
                    更改儲存
                  </Button>
                )
              ))}
          </Col>
        </Row>
      </Container>
      <Modal centered isOpen={isLoading}>
        <Row className="my-4 flex-center">
          <Col className="flex-center">
            <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
            <h4 className="mx-4">Loading</h4>
          </Col>
        </Row>
      </Modal>
      <EnlargeImageModal fileName={enlargeImage} closeAction={() => setEnlargeImage("")} />
    </Modal>
  );
}

interface EnlargeImageModalProps {
  fileName: string;
  closeAction: () => void;
}
export const EnlargeImageModal = ({ fileName, closeAction }: EnlargeImageModalProps) => {
  const MAX_ZOOM_LEVEL = 2;
  const [zoom, setZoom] = useState(1);
  const [moveXY, setMoveXY] = useState([0, 0]);
  const handleZoom = () => {
    if (zoom < MAX_ZOOM_LEVEL) {
      setZoom((zoom) => zoom + 1);
    } else {
      setZoom(1);
      setMoveXY([0, 0]);
    }
  };

  return (
    <Modal centered isOpen={!!fileName} style={{ minWidth: "90vw" }}>
      <Container className="flex-center" style={{ minHeight: "95vh", width: "100%", overflow: "auto" }}>
        <div
          className="absolute p-2 pointer"
          style={{ right: 24, top: 24, background: "#333", borderRadius: "50%" }}
          onClick={() => {
            closeAction();
            setZoom(1);
          }}
        >
          <CloseIcon color="#EEE" />
        </div>
        <img
          src={`https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${fileName}`}
          alt={fileName}
          style={{
            zoom: zoom,
            transition: "all 0.1s",
            transform: `scale(${zoom}) translateX(${moveXY[0] * -1}px) translateY(${moveXY[1] * -1}px)`,
            overflow: "auto",
            cursor: zoom === 1 ? "zoom-in" : "zoom-out",
          }}
          onClick={handleZoom}
          onMouseMove={(e) => {
            if (zoom === 1) {
              return;
            } else {
              const image = e.currentTarget.getBoundingClientRect();
              if (
                e.pageY > image.height * 0.99 ||
                e.pageY < image.height * -0.99 ||
                e.pageX > image.width * 0.99 ||
                e.pageX < image.width * -0.99
              )
                return;
              setMoveXY((moveXY) => [moveXY[0] + e.movementX, moveXY[1] + e.movementY]);
            }
          }}
          onMouseLeave={() => {
            setMoveXY([0, 0]);
          }}
        />
      </Container>
    </Modal>
  );
};
