import axios from "axios";
import "css/Images.css";
import { saveAs } from "file-saver";
import { formatDateAndTime } from "helpers/date";
import JSZip from "jszip";
import { A_DELETE_MEDIA } from "models/authorities";
import { videoFormats } from "models/mediaFormats";
import { FetchedOrderImages } from "models/responseModels";
import { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade } from "redux/Document/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { setImageModalDescription } from "redux/Order/action";
import { IRootState } from "store";
import style from "../css/ImagesRecord.module.scss";
import { DeleteIcon, PlayIcon } from "./IconsOnly";
import DeleteModal from "./parts/DeleteModal";
import FormHeader from "./parts/FormHeader";
import ViewImageModal from "./parts/ViewImageModal";

interface IImagesRecord {
  header: string;
}

export type ImageModalType = "VIEW" | "ADD" | "";

export default function ImagesRecord({ header }: IImagesRecord) {
  const [fetchedImages, setFetchedImages] = useState<FetchedOrderImages[]>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [preview, setPreview] = useState<string | undefined>();
  const [alteredFiles, setAlteredFiles] = useState<File[]>();
  const [imageModal, setImageModal] = useState<{ type: ImageModalType; fileName?: string; mediaId?: number }>({
    type: "",
    fileName: "",
    mediaId: 0,
  });
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const description = useSelector((state: IRootState) => state.orderById.image.imageDescription);
  const changesMade = useSelector((state: IRootState) => state.documents.changesMade);
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const orderCompleteDate = !!orderData.completeDate ? new Date(orderData.completeDate) : undefined;
  const is7DaysAfter =
    !!orderCompleteDate && Date.now().valueOf() > orderCompleteDate.setDate(orderCompleteDate.getDate() + 7).valueOf();

  const dispatch = useDispatch();

  const postMedia = async () => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    if (!!alteredFiles) Array.from(alteredFiles).forEach((f) => formData.append("orderMedia", f));
    if (!!description) formData.append("description", description);
    try {
      await axios.post(`/order-media/${orderId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      fetchAllMediaByOrderId();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    } finally {
      dispatch(setIsLoading(false));
      handleExit();
    }
  };

  const fetchAllMediaByOrderId = async () => {
    try {
      const res = await axios.get(`/order-media/${orderId}`);
      const result = res.data;
      setFetchedImages(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const editMedia = async (mediaId: number) => {
    dispatch(setIsLoading(true));
    try {
      await axios.put(`/order-media/${mediaId}`, {
        description: description,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
      fetchAllMediaByOrderId();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchAllMediaByOrderId();
  }, []);

  const handleExit = () => {
    setImageModal({ type: "" });
    dispatch(setImageModalDescription(""));
    setAlteredFiles(undefined);
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllMediaByOrderId();
      dispatch(setIsLoading(false));
      handleExit();
      dispatch(setChangesMade(false));
    }
  }, [dispatch, changesMade]);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const convertVideoFormat = (video: File) => {
    const blob = video.slice(0, video.size, "video/mp4");
    const arr = video.name.split(".");
    return new File([blob], video.name.slice(0, video.name.length - arr[arr.length - 1].length) + "mp4", {
      type: "video/mp4",
    });
  };

  const uploadMediaHandling = async (files: FileList) => {
    let alteredArr: File[] = [];

    // loop through all files
    for (let f of Array.from(files)) {
      // change the file format if it's quicktime / mov, push the new file in array
      if ([".quicktime", ".QUICKTIME", ".mov", ".MOV"].some((i) => f.name.endsWith(i))) {
        const alteredFile = convertVideoFormat(f);
        alteredArr.push(alteredFile);

        // if the file is image, compress before uploading
      } else if (f.type.startsWith("image")) {
        const compressedImage = await compressImage(f);
        if (compressedImage instanceof File) alteredArr.push(compressedImage);
      } else {
        alteredArr.push(f);
      }
    }
    return alteredArr;
  };

  const onChange = async (event: any) => {
    try {
      if (!event.target.files) {
        // setPreview(undefined);
        setImageModal({ type: "" });
        return;
      } else {
        // check number of files
        if (event.target.files?.length > 5) {
          dispatch(setMessageModal({ isOpen: true, content: "每次只可上傳不多於5個檔案！" }));
          return;
        }

        // convert video format & compress images
        const alteredArr = await uploadMediaHandling(event.target.files);

        // set the new altered array (with both altered & ok files) in use state
        setAlteredFiles(alteredArr);
        setImageModal({ type: "ADD" });
      }
    } catch (error) {
      console.error(error);
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const allowToEdit = (!is7DaysAfter && userRole === "WORKER") || userRole !== "WORKER";

  const handleDownload = async () => {
    const zip = new JSZip();
    const res = await Promise.all(fetchedImages!.map((i) => axios.get<string>(`/order-media/${i.fileName}/file`)));

    for (const i of res.map((r, idx) => [fetchedImages![idx].fileName, r.data] as const)) {
      const parsed = JSON.parse(i[1]);
      const buff = Buffer.from(parsed.blob, "base64");
      zip.file(i[0], buff);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${orderData.orderNumber}.zip`);
    });
  };

  return (
    <div className={`p-3 ${style["content"]}`}>
      <Row>
        <FormHeader
          offset={false}
          header={header}
          includeExtraButton={true}
          children={
            <>
              {fetchedImages && !!fetchedImages.length && (
                <Button className="mx-1" onClick={handleDownload}>
                  下載全部相片
                </Button>
              )}
              {allowToEdit && (
                <form className="flex-center">
                  <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*, video/*, .heic"
                    name="orderMedia"
                    onChange={onChange}
                    multiple
                  />
                  <Button
                    onClick={() => {
                      handleExit();
                      fileInputRef.current?.click();
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    選擇檔案
                  </Button>
                </form>
              )}
            </>
          }
        />
      </Row>
      {allowToEdit ? (
        <h5 className="m-0 disableText full-width flex-center">工作單完工日期7天後，將無法上傳檔案</h5>
      ) : (
        <h5 className="m-0 disableText full-width flex-center">工作單完工日期已過7天，無法上傳檔案</h5>
      )}
      <Row
        className="my-4"
        style={{
          overflow: "scroll",
          maxHeight: "75vh",
          minHeight: "64px",
          display: "grid",
          gridTemplateColumns: !fetchedImages?.length ? "1fr" : "1fr 1fr 1fr",
        }}
      >
        {fetchedImages && !fetchedImages.length ? (
          <Row className="my-4 d-flex justify-content-center" style={{ minHeight: "64px" }}>
            <Col className="d-flex justify-content-center">
              <h5 className="disableText">暫無圖片記錄</h5>
            </Col>
          </Row>
        ) : (
          fetchedImages?.map((item) => {
            return (
              <Col key={item.id} className="my-3 flex-column-start imageContainer">
                {videoFormats.includes(item.fileName.split(".")[1]) ? (
                  <>
                    <div
                      className="full-size flex-column-center"
                      style={{ height: "136px" }}
                      onClick={() => {
                        setImageModal({
                          type: "VIEW",
                          fileName: item.fileName,
                          mediaId: item.id,
                        });
                        dispatch(setImageModalDescription(item.description));
                      }}
                    >
                      <PlayIcon />
                      {item.description && (
                        <div className="my-1 flex-column-start" style={{ maxHeight: "60%", overflowY: "scroll" }}>
                          {item.description}
                        </div>
                      )}
                    </div>
                    <div className="dateContainer">{formatDateAndTime(item.createdAt)}</div>
                  </>
                ) : (
                  <>
                    <img
                      className="image"
                      src={`https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${item.fileName}`}
                      onClick={(e) => {
                        setImageModal({ type: "VIEW", fileName: item.fileName, mediaId: item.id });
                        dispatch(setImageModalDescription(item.description));
                      }}
                      alt={`${item.fileName}`}
                    />
                    <TextareaAutosize
                      className="full-width pointer-events-none flex-column-start py-1"
                      readOnly
                      value={item.description}
                      style={{ border: "none" }}
                    />
                    <div className="dateContainer">{formatDateAndTime(item.createdAt)}</div>
                  </>
                )}
                {A_DELETE_MEDIA.includes(userRole) && (
                  <Button
                    color="danger"
                    className="flex-center imageDeleteButton"
                    onClick={() => {
                      setEditTarget({ name: "", id: item.id });
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon color={"#EEE"} />
                  </Button>
                )}
              </Col>
            );
          })
        )}
      </Row>
      <ViewImageModal
        imageModal={imageModal}
        image={preview}
        handleExit={handleExit}
        onSubmit={allowToEdit ? postMedia : () => {}}
        submitEdit={allowToEdit ? editMedia : () => {}}
        isUploadMode={true}
      />
      <DeleteModal isOpen={deleteModal} deleteTarget={editTarget} setModal={setDeleteModal} addItemString={"圖片"} />
    </div>
  );
}
