import WorkerRedirectModal from "components/parts/WorkerRedirectModal";
import { push } from "connected-react-router";
import style from "css/LoginPage.module.scss";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaKey, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, Input, Modal, Row } from "reactstrap";
import { login, openWorkerRedirect } from "redux/Auth/action";
import { IRootState } from "store";

export interface ILoginData {
  userLogin: string;
  password: string;
}

export default function LoginPage() {
  const loginData = useSelector((state: IRootState) => state.auth);
  const [forgetPasswordPage, setForgetPasswordPage] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginData>();
  const dispatch = useDispatch();

  async function onSubmit(data: ILoginData) {
    if (data.userLogin && data.password) {
      dispatch(login(data));
    }
  }
  const handleExit = () => {
    setForgetPasswordPage(false);
  };

  useEffect(() => {
    const { isAuthenticated, role } = loginData;
    if (isAuthenticated) {
      // redirect users depends on their roles
      if (role === "WORKER") {
        dispatch(openWorkerRedirect());
        return;
      } else if (role === "SUPER_ADMIN") {
        dispatch(push("/admin/dashboard"));
      } else {
        dispatch(push("/service"));
      }
    }
  }, [dispatch, loginData]);

  return (
    <div className={style["background"]}>
      <div id={style["loginSquare"]}>
        <div id={style["heading"]}>
          <h1 style={{ fontSize: "80px" }}>Goldtitan</h1>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} id={style["form"]}>
          <div className={style["row"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "yellow",
              }}
            >
              <FaUser />
            </div>
            <Input
              className={style["input"]}
              type="text"
              {...register("userLogin", { required: true })}
              placeholder="登記電話 ／ 電郵地址"
            />
          </div>
          {errors.userLogin && <div className={style["error"]}>請填寫此項資料</div>}

          <div className={style["row"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "yellow",
              }}
            >
              <FaKey />
            </div>
            <Input
              className={style["input"]}
              type="password"
              {...register("password", { required: true })}
              placeholder="密碼"
            />
          </div>
          {errors.password && <div className={style["error"]}>請填寫此項資料</div>}

          <div
            className="my-4 flex-row-between full-width"
            style={{
              flexFlow: "row nowrap",
            }}
          >
            <Button className="mx-2 w-40" onClick={() => setForgetPasswordPage(true)}>
              忘記密碼
            </Button>
            <Button type="submit" color="primary" className="w-40">
              登入
            </Button>
          </div>
        </Form>
      </div>
      <ForgetPasswordModal
        isOpen={forgetPasswordPage}
        content={`請與公司聯絡 以重設帳戶密碼`}
        handleExit={handleExit}
      />
      <WorkerRedirectModal />
    </div>
  );
}
interface ForgetPasswordModalProps {
  isOpen: boolean;
  content: string;
  handleExit: () => void;
}

export const ForgetPasswordModal = (props: ForgetPasswordModalProps) => {
  const { isOpen, content, handleExit } = props;
  return (
    <Modal centered isOpen={isOpen}>
      <Container className="p-3">
        <Row className="mb-2 flex-center">
          <h6 className="flex-center">{content}</h6>
        </Row>
        <Row className="flex-center">
          <Button className="w-40" onClick={handleExit}>
            OK
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};
