import WorkInfo from "components/WorkInfo";
import Layout from "pages/Layout";

export default function ServiceLobbyPage() {
  return (
    <Layout>
      <WorkInfo header="專案工作單" type="PROJECT" />
    </Layout>
  );
}
